import React from 'react'
import styled from 'styled-components'

const FormContainer = styled.div`
  flex: 1;
  width: 70%;
  border: 1px solid rgb(148, 182, 201);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


  @media (max-width: 768px) {
    width: 100%;
  }
`

const TitleSection = styled.div`
  padding: 1.5rem;
  background-color: white;
`

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
`

const ContentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  background-color:rgb(220, 230, 238);
  padding: 30px;

  & input {
    padding: 12px;
    min-width: 330px;
    border-radius: 15px;
    border: 1px solid #ccc;

    @media (max-width: 768px) {
      min-width: 100%;
    }
    

  & select{
    padding: 12px;
    min-width: 330px;
    border-radius: 15px;
    border: 1px solid #ccc;
  }
  }
`

const FooterSection = styled.div`
  padding: 1.5rem;
  background-color: rgb(220, 230, 238);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
`

export const FormTitle = ({ children }) => (
  <TitleSection>
    <Title>{children}</Title>
  </TitleSection>
)

export const FormContent = ({ children }) => (
  <ContentSection>{children}</ContentSection>
)

export const FormFooter = ({ children }) => (
  <FooterSection>{children}</FooterSection>
)

export const FormalTemplate = ({ title, children, position = 'left', footerButtons }) => (
  <FormContainer position={position}>
    {children}
  </FormContainer>
)

export default FormalTemplate

