import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';

const WelcomeToast = ({ username, lastLogin }) => {
  useEffect(() => {
    const checkLastLogin = () => {
      const currentDate = new Date();
      const lastLoginDate = new Date(lastLogin);
      const daysSinceLastLogin = (currentDate - lastLoginDate) / (1000 * 60 * 60 * 24);
      
      if (daysSinceLastLogin >= 10) {
        toast.success(`Welcome back, ${username}! It's been ${Math.floor(daysSinceLastLogin)} days since your last visit.`, {
          duration: 5000,
          position: 'top-center',
        });
      }
    };

    if (lastLogin) {
      checkLastLogin();
    }
  }, [username, lastLogin]);

  return null; // This component doesn't render anything
};

export default WelcomeToast;