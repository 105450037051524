import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Orders.css';
import Usernavbar from './Usernavbar';
import { toast } from 'react-hot-toast';
import { Loader2 } from 'lucide-react';
import styled from 'styled-components';

const ShipmentsSection = styled.div`
  flex: 2;
  width: 100%;
`;

const ShipmentGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const Card = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: white;
`;

const CardTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const CardDescription = styled.p`
  color: #666;
  margin-bottom: 1rem;
`;

const CardContent = styled.div`
  margin-bottom: 1rem;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16rem;
`;

function Orders() {
  const username = localStorage.getItem('username');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders to display
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [statusFilter, setStatusFilter] = useState(''); // State for status dropdown

  useEffect(() => {
    fetchOrders(username);
  }, [username]); // Fetch orders when the component mounts or username changes

  const fetchOrders = async (username) => {
    try {
      setLoading(true);
      if (!username) {
        console.warn("User ID not found in local storage");
        return;
      }

      const response = await axios.get(
        `https://p2pbackendserver-six.vercel.app/getshipments/${username}`
      );

      if (response.data.length === 0) {
        setOrders([]); // Set an empty state if no shipments are found
        return;
      }

      setOrders(response.data);
      setFilteredOrders(response.data); // Initially display all orders
    } catch (error) {
      console.error('Error retrieving shipments:', error);
      toast.error("Failed to fetch shipments. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to handle search button click
  const handleSearch = () => {
    filterOrders(searchQuery, statusFilter);
  };

  // Function to handle status dropdown change
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    filterOrders(searchQuery, e.target.value);
  };

  // Function to filter orders based on search query and status
  const filterOrders = (search, status) => {
    let filtered = orders;

    if (search) {
      filtered = filtered.filter((order) =>
        order.trackingId.toLowerCase().includes(search.toLowerCase()) ||
        order.shopBuyingFrom.toLowerCase().includes(search.toLowerCase()) ||
        order.courierName.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (status) {
      filtered = filtered.filter((order) => order.status === status);
    }

    setFilteredOrders(filtered); // Set filtered orders state
  };

  const handleClick = () => {
    window.location.href = '/shipment';
  };

  return (
    <>
      <Usernavbar />
      <div className='orders-container'>
        <div className="filters-section">
          {/* Search Input */}
          <div className="search-div">
            <input
              type="text"
              placeholder="Search orders..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
            />
            <button onClick={handleSearch} className="search-button">
              🔍 {/* Magnifying glass icon */}
            </button>
          </div>

          {/* navigate to orders page */}


          {/* Status Dropdown */}
          <div className="status-dropdown">
            <select value={statusFilter} onChange={handleStatusChange}>
            <option value="">Search Status</option>
            <option value="Pending">Pending</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            </select>
          </div>
        </div>

        {/* Orders Display */}
        <ShipmentsSection>
          {loading ? (
            <LoaderContainer>
              <Loader2 size={32} />
            </LoaderContainer>
          ) : filteredOrders.length > 0 ? (
            <ShipmentGrid>
              {filteredOrders.map((shipment) => (
                <Card key={shipment.trackingId}>
                  <CardTitle>{shipment.username}</CardTitle>
                  <CardDescription>Tracking ID: {shipment.trackingId}</CardDescription>
                  <CardContent>
                    <p><strong>Shop:</strong> {shipment.shopBuyingFrom}</p>
                    <p><strong>Courier:</strong> {shipment.courierName}</p>
                    <p><strong>ETA:</strong> {new Date(shipment.eta).toLocaleDateString()}</p>
                  </CardContent>
                  <CardFooter>
                    {/* <DeleteButton onClick={() => handleDelete(shipment.id)}>
                      <Trash2 size={16} />
                      Delete
                    </DeleteButton> */}
                  </CardFooter>
                </Card>
              ))}
            </ShipmentGrid>
          ) : (
            <p>No shipments found. Create a new shipment to get started.</p>
          )}
        </ShipmentsSection>
      </div>
    </>
  );
}

export default Orders;
