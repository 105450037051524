import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding-top: 20px;
`;

const NotificationsPanel = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  position: relative;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(220, 230, 238);
`;

const NotificationsList = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
`;

const TableHeader = styled.thead`
  background-color: rgb(220, 230, 238);
  color: #333;
  font-weight: 500;
`;

const HeaderCell = styled.th`
  padding: 12px 16px;
  text-align: left;
  font-size: 14px;
  background-color: rgba(220, 230, 238, 0.2);
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const NotificationItem = styled.tr`
  background-color: ${props => props.isHighlighted ? 'rgba(220, 230, 238, 0.4)' : '#ffffff'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: rgba(220, 230, 238, 0.2);
    transform: translateY(-2px);
  }
`;

const NotificationCell = styled.td`
  padding: 12px 16px;
  font-size: 13px;
  color: #555;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  padding: 24px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #999;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #333;
  }
`;

const NotificationsPage = ({ highlightedShipment }) => {
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const username = localStorage.getItem('username');

  useEffect(() => {
    const fetchShipments = async () => {
      try {
        const response = await axios.get(
          `https://p2pbackendserver-six.vercel.app/getallshipments`
        );  
        if (response.data.length === 0) {
          setShipments([]);
          return;
        }
    
        setShipments(response.data);
      } catch (error) {
        console.error('Error fetching shipments:', error);
      }
    };

    fetchShipments();
  }, []);

  const handleRowClick = (shipment) => {
    setSelectedShipment(shipment);
  };

  const closeModal = () => {
    setSelectedShipment(null);
  };

  return (
    <PageContainer>
      <NotificationsPanel>
        <Title>Recent Shipments</Title>
        <NotificationsList>
          <TableHeader>
            <tr>
              <HeaderCell>Username</HeaderCell>
              <HeaderCell>Tracking ID</HeaderCell>
              <HeaderCell>Shop</HeaderCell>
              <HeaderCell>Courier</HeaderCell>
              <HeaderCell>ETA</HeaderCell>
            </tr>
          </TableHeader>
          <tbody>
            {shipments.map(shipment => (
              <NotificationItem 
                key={shipment._id} 
                isHighlighted={shipment._id === highlightedShipment}
                onClick={() => handleRowClick(shipment)}
              >
                <NotificationCell>{shipment.username}</NotificationCell>
                <NotificationCell>{shipment.trackingId}</NotificationCell>
                <NotificationCell>{shipment.shopBuyingFrom}</NotificationCell>
                <NotificationCell>{shipment.courierName}</NotificationCell>
                <NotificationCell>{new Date(shipment.eta).toLocaleDateString()}</NotificationCell>
              </NotificationItem>
            ))}
          </tbody>
        </NotificationsList>
      </NotificationsPanel>

      {selectedShipment && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            <h3>Shipment Details</h3>
            <span><strong>Username:</strong> {selectedShipment.username}</span><br />
            <span><strong>Tracking ID:</strong> {selectedShipment.trackingId}</span><br />
            <span><strong>Shop:</strong> {selectedShipment.shopBuyingFrom}</span><br />
            <span><strong>Courier:</strong> {selectedShipment.courierName}</span><br />
            <span><strong>ETA:</strong> {new Date(selectedShipment.eta).toLocaleDateString()}</span><br />
          </ModalContent>
        </ModalOverlay>
      )}
    </PageContainer>
  );
};

export default NotificationsPage;