import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import styled from 'styled-components'
import Usernavbar from './Usernavbar';
import { FormalTemplate, FormTitle, FormContent, FormFooter } from '../components/FormalTemplate'
import { Package, Trash2, Loader2, RefreshCw } from 'lucide-react'
import Notification from '../components/AdminNotificationPopup'
import io from 'socket.io-client'

const Container = styled.div`
  width: 96%;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`

const TitleEncap = styled.div`
  padding: 1.5rem;
  background-color: white;
`

const InPutencap = styled.div`
  padding: 1.5rem;
  background-color:rgb(246, 250, 254);
  display: flex;
  flex-direction: column;
  gap: 1rem;  
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  padding: 6px;
`

const ShipmentsSection = styled.div`
  flex: 2;
  width: 100%;
`

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  background-color: #f8f9fa;
  background-color: white;
  border-radius: 0.5rem;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Label = styled.label`
  font-weight: bold;
`

const Input = styled.input`
`

const ShipmentGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const Card = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: white;
`

const CardTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const CardDescription = styled.p`
  color: #666;
  margin-bottom: 1rem;
`

const CardContent = styled.div`
  margin-bottom: 1rem;
`

const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DeleteButton = styled(Button)`
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16rem;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

const ShipmentManagement = () => {
  const [shipments, setShipments] = useState([])
  const [loading, setLoading] = useState(true)
  const userName = localStorage.getItem('username')
  const [formData, setFormData] = useState({
    username: userName,
    trackingId: '',
    shopBuyingFrom: '',
    courierName: '',
    eta: ''
  })
  const [notification, setNotification] = useState(null)
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    fetchShipments()

    const newSocket = io('https://p2pbackendserver-six.vercel.app');
    setSocket(newSocket)

    return () => {
      if (newSocket) {
        newSocket.disconnect()
      }
    }
  }, [userName])

  const fetchShipments = async () => {
    try {
      setLoading(true);
  
      const username = localStorage.getItem('username');
      if (!username) {
        console.warn("User ID not found in local storage");
        return;
      }
  
      const response = await axios.get(
        `https://p2pbackendserver-six.vercel.app/getshipments/${username}`
      );  
      if (response.data.length === 0) {
        setShipments([]); // Set an empty state if no shipments are found
        return;
      }
  
      setShipments(response.data); // Update state with fetched shipments
    } catch (error) {
      console.error('Error retrieving shipments:', error);
      toast.error("Failed to fetch shipments. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  
    try {
      // Retrieve the _id from local storage

      await axios.post('https://p2pbackendserver-six.vercel.app/createshipment', formData)
      toast.success("Shipment created successfully.")
      fetchShipments()
      clearForm()

      // Send Socket.io message
      if (socket) {
        socket.emit('new_shipment', {
          username: formData.username,
          trackingId: formData.trackingId
        })
      }

      // // Show notification
      // setNotification(`User ${formData.username} has submitted a new shipment`)
    } catch (error) {
      console.error('Error creating shipment:', error)
      toast.error("Failed to create shipment. Please try again.")
    }
  }
  

  // const handleDelete = async (id) => {
  //   try {
  //     await axios.delete(`https://p2pbackendserver-six.vercel.app/deleteshipment/${id}`)
  //     toast.success("Shipment deleted successfully.")
  //     fetchShipments()
  //   } catch (error) {
  //     console.error('Error deleting shipment:', error)
  //     toast.error("Failed to delete shipment. Please try again.")
  //   }
  // }

  const clearForm = () => {
    setFormData({
      username: userName,
      trackingId: '',
      shopBuyingFrom: '',
      courierName: '',
      eta: ''
    })
  }

  return (
    <>
    <Usernavbar />
    <Container>
      <Layout>
        <FormalTemplate>
          <Form onSubmit={handleSubmit}>
            <FormTitle>
                <h3>Tell us about the package you are sending to our address.</h3>
            </FormTitle>

                <FormContent>

                <FormGroup>
                  <Input placeholder={userName} style={{backgroundColor: '#ebeff3'}} id="name" name="name" readOnly />
                </FormGroup>
                <FormGroup>
                  <Input placeholder='Enter your tracking ID' id="trackingId" name="trackingId" value={formData.trackingId} onChange={handleInputChange} required />
                </FormGroup>
                <FormGroup>
                  <Input placeholder='Enter the name of the shop you are buying from' id="shopBuyingFrom" name="shopBuyingFrom" value={formData.shopBuyingFrom} onChange={handleInputChange} required />
                </FormGroup>
                <FormGroup>
                  <Input placeholder='Enter your courier name' id="courierName" name="courierName" value={formData.courierName} onChange={handleInputChange} required />
                </FormGroup>
                <FormGroup>
                  <Input placeholder='Enter the estimated time of arrival' id="eta" name="eta" type="date" value={formData.eta} onChange={handleInputChange} required />
                </FormGroup>

                </FormContent>


            <FormFooter>
            <ButtonGroup>
              <Button type="submit">
                <Package size={16} />
                Create Shipment
              </Button>
              <Button type="button" onClick={clearForm}>
                <RefreshCw size={16} />
                Clear Fields
              </Button>
            </ButtonGroup>
            </FormFooter>
          </Form>
        </FormalTemplate>

        {/* <ShipmentsSection>
          {loading ? (
            <LoaderContainer>
              <Loader2 size={32} />
            </LoaderContainer>
          ) : shipments.length > 0 ? (
            <ShipmentGrid>
              {shipments.map((shipment) => (
                <Card key={shipment.id}>
                  <CardTitle>{shipment.username}</CardTitle>
                  <CardDescription>Tracking ID: {shipment.trackingId}</CardDescription>
                  <CardContent>
                    <p><strong>Shop:</strong> {shipment.shopBuyingFrom}</p>
                    <p><strong>Courier:</strong> {shipment.courierName}</p>
                    <p><strong>ETA:</strong> {new Date(shipment.eta).toLocaleDateString()}</p>
                  </CardContent>
                  <CardFooter> */}
                    {/*place this in admin page later on*}
                    {/* <DeleteButton onClick={() => handleDelete(shipment.id)}>
                      <Trash2 size={16} />
                      Delete
                    </DeleteButton> */}
                  {/* </CardFooter>
                </Card>
              ))}
            </ShipmentGrid>
          ) : (
            <p>No shipments found. Create a new shipment to get started.</p>
          )}
        </ShipmentsSection> */}
      </Layout>
    </Container>
    {notification && (
        <Notification
          message={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  )
}

export default ShipmentManagement

