import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTruck, FaSpinner, FaCheckCircle } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import Usernavbar from './Usernavbar';
import WelcomeToast from '../components/WelcomeToast';

const DashboardContainer = styled.div`
  padding: 20px;
  background-color: #ebeff3;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;



const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StatBox = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  flex: 1;
  margin: 0 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 115px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const StatIcon = styled.div`
  font-size: 24px;
  color: #06397c;
`;

const StatTitle = styled.p`
  font-size: 14px;
  color: #666;
`;

const StatFigure = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #06397c;
`;

const ChartContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChartTitle = styled.p`
  text-align: center;
  margin-bottom: 20px;
  color:rgb(6, 106, 124);
`;

const XAxisTick = styled.text`
  font-size: 12px;
  fill: #06397c;
`;


const Dashboard = () => {
  const [shipmentData, setShipmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [totalDeliveries, setTotalDeliveries] = useState(0);
  const [pending, setPending] = useState(0);
  const [inProgress, setInProgress] = useState(0);
  const [completed, setCompleted] = useState(0);
  

  useEffect(() => {
    const username = localStorage.getItem('username');
    const lastLogin = localStorage.getItem('lastLogin');
    setUserData({ username, lastLogin });
  }, []);

  useEffect(() => {
    const fetchShipmentData = async () => {
      try {
        const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/number-of-shipments'); // Replace with your actual API endpoint
        const data = response.data;
        
        const monthlyData = processMonthlyData(data);
        setShipmentData(monthlyData);
        setIsLoading(false);
      } catch (err) {
        console.error('Failed to fetch shipment data:', err);
        setError('No Orders Recorded This Year');
        setShipmentData(getDefaultData());
        setIsLoading(false);
      }
    };

    fetchShipmentData();
  }, []);


  const fetchStats = async () => {
    try {
      const response = await axios.get(`https://p2pbackendserver-six.vercel.app/shipments/${userData.username}/stats`);
      const { totalOrders, pendingOrders, inProgressOrders, completedOrders } = response.data;

      setTotalDeliveries(totalOrders);
      setPending(pendingOrders);
      setInProgress(inProgressOrders);
      setCompleted(completedOrders);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to fetch stats');
      setIsLoading(false);
    }
  };

  const fetchMonthlySummary = async () => {
    try {
      const response = await axios.get(`https://p2pbackendserver-six.vercel.app/shipments/${userData.username}/monthly-summary`);
      setShipmentData(response.data);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userData && userData.username) {
      fetchStats();
      fetchMonthlySummary();
    }
  }, [userData]);
  


  const processMonthlyData = (data) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentYear = new Date().getFullYear();
    
    const monthlyTotals = months.map((month, index) => ({
      month,
      total: data.filter(shipment => {
        if (!shipment.date) return false;
        const shipmentDate = new Date(shipment.date);
        return shipmentDate.getFullYear() === currentYear && shipmentDate.getMonth() === index;
      }).length
    }));

    return monthlyTotals.length > 0 ? monthlyTotals : getDefaultData();
  };

  const getDefaultData = () => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months.map(month => ({ month, total: 0 }));
  };

  if (!userData) {
    return <div>Loading...</div>;
  }
  

  return (
    <>
      <Usernavbar />
      <WelcomeToast username={userData.username} lastLogin={userData.lastLogin} />
      <DashboardContainer>
        <StatsContainer>
          <StatBox>
            <StatIcon><FaTruck /></StatIcon>
            <StatTitle>Total Orders</StatTitle>
            <StatFigure>{totalDeliveries}</StatFigure>
          </StatBox>
          <StatBox>
            <StatIcon><FaSpinner /></StatIcon>
            <StatTitle>Pending Orders</StatTitle>
            <StatFigure>{pending}</StatFigure>
          </StatBox>
          <StatBox>
            <StatIcon><FaTruck /></StatIcon>
            <StatTitle>In Progress</StatTitle>
            <StatFigure>{inProgress}</StatFigure>
          </StatBox>
          <StatBox>
            <StatIcon><FaCheckCircle /></StatIcon>
            <StatTitle>Completed Orders</StatTitle>
            <StatFigure>{completed}</StatFigure>
          </StatBox>
        </StatsContainer>

        <ChartContainer>
          <ChartTitle>Total Orders Made This Year: <strong>{totalDeliveries}</strong></ChartTitle>
          <div style={{ width: '100%', height: '200px', position: 'relative' }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={shipmentData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="month" 
                  tick={({ x, y, payload }) => (
                    <XAxisTick x={x} y={y} dominantBaseline="hanging" textAnchor="middle">
                      {payload.value}
                    </XAxisTick>
                  )}
                />
                <Tooltip />
                <Line 
                  type="monotone" 
                  dataKey="total" 
                  stroke="#3f6496" 
                  strokeWidth={1}
                  dot={{ r: 4, fill: '#3f6496' }}
                  activeDot={{ r: 8, fill: '#3f6496' }}
                />
              </LineChart>
            </ResponsiveContainer>
            {isLoading && (
              <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading chart data...
              </div>
            )}
            {error && (
              <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <span>{error}</span>
              </div>
            )}
          </div>
        </ChartContainer>
      </DashboardContainer>
    </>
  );
};

export default Dashboard;