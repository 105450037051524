import React from 'react';
import styled from 'styled-components';
import { MessageSquare, X } from 'lucide-react';

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  align-items: center;
  max-width: 400px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  cursor: pointer;

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  margin-right: 12px;
  color: #3498db;
`;

const MessageWrapper = styled.div`
  flex: 1;
`;

const Message = styled.p`
  margin: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.4;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  padding: 4px;
  margin-left: 8px;
  transition: color 0.2s ease;

  &:hover {
    color: #333;
  }
`;

// interface NotificationProps {
//   message: string;
//   onClose: () => void;
//   onClick: () => void;
// }

const Notification = ({ message, onClose, onClick }) => {
  return (
    <NotificationContainer onClick={onClick}>
      <IconWrapper>
        <MessageSquare size={24} />
      </IconWrapper>
      <MessageWrapper>
        <Message>{message}</Message>
      </MessageWrapper>
      <CloseButton onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}>
        <X size={16} />
      </CloseButton>
    </NotificationContainer>
  );
};

export default Notification;