import React, { useState, useEffect } from 'react';
import '../styles/Dash.css';
import shipping from '../assets/images/upper-globe-img.mp4';
import '../styles/CreateTracking.css';
import Logo from '../components/Logo';
import Notification from '../components/AdminNotificationPopup';
import NotificationsPage from '../components/AdminNotification';
import io from 'socket.io-client'

import FirstComponent from '../components/Stats';
import SecondComponent from '../components/GenerateTracking';
import ThirdComponent from '../components/TrackingInformation';
import FourthComponent from '../components/ScheduleAdmin';
import FifthComponent from '../components/Dropdates';
import sixthComponent from '../pages/CreateArticle';
import seventhComponent from '../components/ChangePassword';
import eighthComponent from '../components/AdminNotification';

const stats = 'https://img.icons8.com/ios/50/e5e5e5/combo-chart--v1.png';
const generate = 'https://img.icons8.com/ios-filled/50/e5e5e5/create-new.png';
const tracking = 'https://img.icons8.com/ios-glyphs/30/e5e5e5/order-shipped.png';
const schedule = 'https://img.icons8.com/ios/50/e5e5e5/overtime--v1.png';
const blog = 'https://img.icons8.com/ios-filled/50/e5e5e5/blog.png';

const tabs = [
  { id: 1, icon: stats, name: 'Stats', component: FirstComponent },
  { id: 2, icon: generate, name: 'Generate Tracking', component: SecondComponent },
  { id: 3, icon: tracking, name: 'Tracking Information', component: ThirdComponent },
  { id: 4, icon: schedule, name: 'Create Dropdates', component: FourthComponent },
  { id: 5, icon: schedule, name: 'View Dropdates', component: FifthComponent },
  { id: 6, icon: blog, name: 'Create Blog Article', component: sixthComponent },
  { id: 7, icon: generate, name: 'Reset Password', component: seventhComponent },
  { id: 8, icon: generate, name: 'Notifications', component: eighthComponent }
];

const Dash = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 768);
  const [notification, setNotification] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [highlightedShipment, setHighlightedShipment] = useState(null);
  const userName = localStorage.getItem('username');


  useEffect(() => {
    if (userName === 'p2p_superadmin') {
      const socket = io('https://p2pbackendserver-six.vercel.app');

      socket.emit('auth', { username: userName });

      socket.on('new_shipment_notification', (data) => {
        setNotification({
          message: `New shipment submitted by ${data.username} (Tracking ID: ${data.trackingId})`,
          type: 'info',
          shipmentId: data.shipmentId
        });
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [userName]);

  const handleNotificationClick = () => {
    setShowNotifications(true);
    setHighlightedShipment(notification.shipmentId);
  };

  const handleNotificationsClose = () => {
    setShowNotifications(false);
    setHighlightedShipment(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarVisible(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabClick = (id) => {
    if (id === activeTab) return; // Do nothing if the tab is already active
    setActiveTab(id);
    setIsLoading(true);

    // Only hide the sidebar if the window width is less than or equal to 768px
    if (window.innerWidth <= 768) {
      setIsSidebarVisible(false);
    }
  };

  

  useEffect(() => {
    setIsLoading(false);
  }, [activeTab]);

  const ActiveComponent = tabs.find(tab => tab.id === activeTab).component;


  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handlehomenav = () => {
    window.location.href = '/';
  }

  
  return (
    <div className="dash-dashboard">
      <div className='innerdashdash'>
        <div className={`dash-sidebar ${isSidebarVisible ? '' : 'hidden'}`}>
        <div className='admin-profile-avatar' onClick={handlehomenav}>
        <h5>ADMIN</h5>
      </div>
          <div className="dash-tabs">
            {tabs.map(tab => (
              <div
                key={tab.id}
                className={`dash-tab ${activeTab === tab.id ? 'active' : ''}`}
                onClick={() => handleTabClick(tab.id)}
              >
                <img width="20" height="20" src={tab.icon} alt="rebalance-portfolio" />
                {tab.name}
              </div>
            ))}
          </div>
        </div>

        <div
          className={`dash-hamburger ${isSidebarVisible ? 'open' : ''}`}
          onClick={toggleSidebar}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className={`dash-content ${isSidebarVisible ? '' : 'dash-sidebar-hidden'}`}>
          <div className="create-tracking-container">
            <div className="tracking-home-section">
              <video loading='lazy' src={shipping} autoPlay loop muted preload="none" />
              <div className="tracking-overlay"></div>
              <div className="tracking-content">
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className='intro-box'>
              <div className="three-body">
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
              </div>
            </div>
          ) : (
            <ActiveComponent />
          )}
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          onClose={() => setNotification(null)}
          onClick={handleNotificationClick}
        />
      )}
      {showNotifications && (
        <NotificationsPage 
          onClose={handleNotificationsClose} 
          highlightedShipment={highlightedShipment}
        />
      )}
    </div>
  );
};

export default Dash;
