import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Usernavbar.css';
import { useAtom } from 'jotai';
import { activeTabNameAtom } from '../StateManagement/Store';


function Usernavbar() {
    const [userData, setUserData] = useState({});
    const [activeTabName] = useAtom(activeTabNameAtom);
    const currentyear = new Date().getFullYear();
    const id= localStorage.getItem('_id');

    useEffect(() => {
        const token = localStorage.getItem('token');

      
        if (token) {
          axios
            .get('https://p2pbackendserver-six.vercel.app/api/user/profile', {
              headers: {
                'Authorization': `Bearer ${token}` 
              }
            })
            .then((response) => { 
              const userData = response.data;
              console.log('User data:', userData);
              setUserData(userData);
            })
            .catch((error) => {
              console.error('Error fetching user data:', error);
            });
        }
      }, []);
  return (
    <div className="user-profile-navbar">
      <div className="user-profile-title-section">
        <button onClick={() => window.history.back()} className="back-button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3d677f" stroke-width="2.1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-house"><path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8"/><path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/></svg></button>
        <p>{activeTabName}</p>
      </div>
        <div className='greeting'>
        <span><strong>Customer ID:</strong> p2p-{id}-{currentyear}</span>
        <span>Hi <strong>{userData.firstname }</strong></span>
        <div className="user-icon">{userData.firstname?.[0].toUpperCase()}{userData.lastname?.[0].toUpperCase()}</div>
        </div>
    </div>
      
  )
}

export default Usernavbar
